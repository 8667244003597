body {
  margin: 0;
  font-family: "source-sans-pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.App-select-field {
  font-size: 18px;
  font-weight: 500;
  font-family: canada-type-gibson;
  -webkit-letter-spacing: 0.2px;
  -moz-letter-spacing: 0.2px;
  -ms-letter-spacing: 0.2px;
  letter-spacing: 0.2px;
  line-height: 21px;
  margin-bottom: 10px;
  color: #4b6a88;
  width: 100%;
  margin: 0;
  text-overflow: ellipsis;
  padding-left: 5px;
  padding-right: 15px;
  padding-bottom: 5px;
  border: none;
  border-style: solid;
  border-width: 0 0 3px 0;
  border-radius: 0;
  border-color: #d5dee7;
  padding-top: 20px;
  -webkit-transition: border-color linear 0.2s;
  transition: border-color linear 0.2s;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

div[data-lastpass-icon-root] {
  display: none;
}
